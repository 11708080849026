import { useIntl } from '@leagueplatform/locales';
import { ROUTE_PATHS, EXTERNAL_URLS } from 'common/constants';
import type { Link, LocalesKey } from 'common/types';
import OvatientExternalLinkIcon from 'assets/ovatient-external-link-icon.svg';

export type Option = {
  heading?: LocalesKey;
  items: Link[];
};

export function useUserDropdownConfig(
  handleDeleteAccount: () => void,
): Option[] {
  const { formatMessage } = useIntl();

  return [
    {
      items: [
        {
          text: formatMessage({ id: 'STR_GO_TO_MYCHART' }),
          to: EXTERNAL_URLS.MYCHART_HOME,
          icon: OvatientExternalLinkIcon,
          linkIcon: OvatientExternalLinkIcon,
          target: '_blank',
        },
      ],
    },
    {
      heading: formatMessage({ id: 'STR_ACCOUNT_SETTINGS' }),
      items: [
        {
          text: formatMessage({ id: 'STR_PERSONAL_INFO' }),
          to: ROUTE_PATHS.PERSONAL_INFO,
        },
        {
          text: formatMessage({ id: 'STR_COMMUNICATION' }),
          to: ROUTE_PATHS.COMMUNICATION_PREFERENCE,
        },
        {
          text: formatMessage({ id: 'STR_MANAGE_ACCOUNT' }),
          to: EXTERNAL_URLS.MANAGE_ACCOUNT,
        },
      ],
    },
    {
      heading: formatMessage({ id: 'STR_ABOUT' }),
      items: [
        {
          text: formatMessage({ id: 'STR_ABOUT_MY_CARE_ANYWHERE' }),
          to: ROUTE_PATHS.ABOUT,
        },
        {
          text: formatMessage({ id: 'STR_PRIVACY_POLICY_NAV_TEXT' }),
          to: EXTERNAL_URLS.PRIVACY_POLICY,
        },
        {
          text: formatMessage({ id: 'STR_TERMS_OF_SERVICE_NAV_TEXT' }),
          to: EXTERNAL_URLS.TERMS_OF_SERVICE,
        },

        {
          text: formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' }),
          onClick: handleDeleteAccount,
        },
      ],
    },
    {
      items: [
        {
          text: formatMessage({ id: 'STR_SIGN_OUT' }),
          to: ROUTE_PATHS.SIGN_OUT,
          linkProps: {
            color: 'onSurface.text.critical',
            fontWeight: 'semibold',
          },
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ].map(
    // Adds default linkProps to each item in the user dropdown
    ({ items, ...restOption }) =>
      ({
        ...restOption,
        items: items.map((item) => ({
          linkProps: {
            color: 'onSurface.text.primary',
            fontWeight: 'semibold',
          },
          ...item,
        })),
      } as Option),
  );
}
