import { getLocaleConstant } from 'utils/get-locale-constant.util';

export const FAQ = '';

export const TERMS_CONDITION_LINK = `/terms-of-use`;
export const PRIVACY_POLICY_LINK = `/privacy-policy`;
export const TERMS_OF_USE_ROUTE = `/home`;
export const PRIVACY_POLICY_ROUTE = `/home`;
export const FORMS_CENTER_ROUTE = `/forms-center`;
export const HOME = `/home`;

export const CARE_ADVISOR_EMAIL = 'careadvisorconnect@optum.com';
export const CARE_ADVISOR_PHONE_NUMBER = '866-658-4644';
export const VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH =
  '/view-health-authorization';

export const NPSSurveyConfig = {
  interceptId: 'SI_2nKmq5YVHcToqma',
  brandId: 'uhg1',
  projectId: 'ZN_3WAcqHSsRfyx3b8',
};

export const MASONRY = {
  API_VERSION: 1,
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
  MODULE_KEY_CLAIMS: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
};

export const CALLBACK_STATUS = {
  QUEUED: 'QUEUED',
  COMPLETED: 'COMPLETED',
};

export const ROUTE_PATHS = {
  HOME: '/home',
  PERSONAL_INFO: '/personal-info',
  COMMUNICATION_PREFERENCE: '/communication-preference',
  SIGN_OUT: '/sign-out',
  ABOUT: '/about-my-care-anywhere',
};

export const EXTERNAL_URLS = {
  REGISTER: 'https://mychart.metrohealth.org/MyChart/Signup',
  PRIVACY_POLICY: getLocaleConstant({
    en: 'https://www.metrohealth.org/patients-and-visitors/for-visitors/privacy-notice',
    es: 'https://www.metrohealth.org/-/media/metrohealth/documents/patients-and-visitors/privacy-policy-notice_3-2021-espanol.pdf?la=en&hash=B8DF181D3F40201B2DE8D805A8AC0731E7174C48',
  }),
  TERMS_OF_SERVICE: getLocaleConstant({
    en: 'https://www.metrohealth.org/-/media/metrohealth/documents/patients-and-visitors/terms-and-conditions-social-media.pdf',
    es: 'https://mychart.metrohealth.org/MyChart/Authentication/Login?mode=stdfile&option=termsandconditions&lang=es-US',
  }),
  MANAGE_ACCOUNT: 'https://mychart.metrohealth.org/MyChart/PersonalInformation',
  MYCHART_HOME: 'https://mychart.metrohealth.org/mychart/Authentication/Login',
};

export const MASONRY_WS_CONTAINERS = {
  HOME_PAGE: 'ovatient-metrohealth_home',
};

export const MUTATION_KEYS = {
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
};

export const REST_ENDPOINTS = {
  USERS: '/v1/users',
};
