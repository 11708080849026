import { useMutation, useQueryClient } from 'react-query';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  setUserProfile,
  UserProfile,
} from '@leagueplatform/user-profile-api';
import { SENTRY_CONSTANTS, getError } from '@ovatient-web/shared-module';
import { captureAppError } from '@leagueplatform/observability';

export function useUpdateUserProfile() {
  const queryClient = useQueryClient();
  const updateUserProfileMutation = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess() {
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]);
      },
      onError(error) {
        captureAppError(getError(error), {
          ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
          errorName: 'Error updating the users profile',
        });
      },
    },
  );

  return updateUserProfileMutation;
}
