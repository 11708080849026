import type { GDSIconProps } from '@leagueplatform/genesis-core';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

enum NavIds {
  HOME = 'STR_HOME',
  ABOUT_ME = 'STR_ABOUT_ME',
  CARE = 'STR_GET_CARE',
  JOURNEY = 'STR_JOURNEY',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  icon?: GDSIconProps['icon'];
}

export const useNavLinksList = () => {
  const NavLinksList: NavLink[] = [
    {
      to: '/home',
      message_id: NavIds.HOME,
      icon: 'illustrativeHome',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NavIds.CARE,
      icon: 'illustrativeHeart',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NavIds.JOURNEY,
      icon: 'capabilityJourney',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.aboutMe),
      message_id: NavIds.ABOUT_ME,
      icon: 'illustrativePerson',
    },
  ];

  return {
    navLinks: NavLinksList,
  };
};
