import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  Icon,
  Modal,
  Divider,
  Box,
} from '@leagueplatform/genesis-core';
import { useLocation, NavLink } from '@leagueplatform/routing';
import {
  NavLink as NavLinkInterface,
  useNavLinksList,
} from 'components/header-nav/navbar/use-nav-links-list.hook';
import {
  sendCapabilityNavLinkEvent,
  sendUserOptionEvent,
} from 'utils/analytics-events';
import {
  MobileNavLink,
  defaultMobileNavLinkStyles,
} from 'components/header-nav/navbar/mobile-nav/mobile-nav.links.component';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';

type MobileNavProps = {
  closeModal: () => void;
  handleDeleteAccount: () => void;
};

export const MobileNav = ({
  closeModal,
  handleDeleteAccount,
}: MobileNavProps) => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const location = useLocation();
  const handleCapabilityNavLinkClick = (action: string) => {
    closeModal();
    sendCapabilityNavLinkEvent(action);
  };
  const mainNavLinks = navLinks.map(
    ({ message_id, icon, to }: NavLinkInterface) => {
      const isActiveLink = location.pathname.startsWith(to as string);
      return (
        <Box
          as={NavLink}
          to={to as string}
          id={`${formatMessage({ id: message_id })}-link`}
          key={`${formatMessage({ id: message_id })}-link`}
          css={defaultMobileNavLinkStyles}
          onClick={() => handleCapabilityNavLinkClick(message_id)}
        >
          {icon && (
            <Icon
              icon={isActiveLink ? `${icon}Filled` : icon}
              css={{ marginRight: '$half' }}
            />
          )}
          {formatMessage({ id: message_id })}
        </Box>
      );
    },
  );

  const userDropdownItems = useUserDropdownConfig(handleDeleteAccount);

  return (
    <StackLayout as="nav" spacing="$half" horizontalAlignment="stretch">
      <Modal.Title>
        <HeadingText
          level="2"
          size="xl"
          css={{
            paddingInlineStart: '$half',
            marginBlockEnd: '$threeQuarters',
          }}
        >
          {formatMessage({ id: 'STR_MENU' })}
        </HeadingText>
      </Modal.Title>
      <StackLayout
        as="ul"
        spacing="$threeQuarters"
        horizontalAlignment="stretch"
      >
        {mainNavLinks}
      </StackLayout>
      {userDropdownItems.map(({ heading, items }) => (
        <StackLayout
          spacing={heading ? '$threeQuarters' : '$half'}
          css={{
            width: '100%',
          }}
          key={`mobile_nav--${heading || items[0]?.text}`}
          horizontalAlignment="stretch"
        >
          <Divider
            css={{ marginInline: '$half', width: 'calc(100% - $half)' }}
          />
          <StackLayout spacing="$half" horizontalAlignment="stretch">
            {heading && (
              <HeadingText
                level="3"
                size="md"
                css={{
                  paddingInlineStart: '$half',
                  marginBlockEnd: '$quarter',
                }}
              >
                {heading}
              </HeadingText>
            )}
            <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
              {items.map((item) => {
                const { text } = item;
                const handleOptionClick = () => {
                  closeModal();
                  sendUserOptionEvent(text);
                  if ('onClick' in item) item.onClick();
                };
                return (
                  <MobileNavLink
                    linkData={item}
                    onLinkClick={handleOptionClick}
                    key={`mobile-nav-link--${text}`}
                  >
                    {item.icon && !item.linkIcon && (
                      <Icon
                        icon={item.icon}
                        size="$oneAndHalf"
                        css={{ marginRight: '$half' }}
                      />
                    )}
                    {text}
                    {item.linkIcon && (
                      <Icon
                        icon={item.linkIcon}
                        size="$one"
                        css={{
                          marginInlineStart: '$one',
                          alignSelf: 'center',
                        }}
                      />
                    )}
                  </MobileNavLink>
                );
              })}
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ))}
    </StackLayout>
  );
};
