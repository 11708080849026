import { socketAsFetch } from '../utils/socket-as-fetch';

const ACCEPT_MEMBER_TERMS_TYPE = 'accept_member_terms';

const TERMS_VERSION = '2.0';

export const acceptUserTerms = () =>
  socketAsFetch({
    message_type: ACCEPT_MEMBER_TERMS_TYPE,
    info: {
      version: TERMS_VERSION,
    },
  });
