import { OnboardingRoutesWithProvider } from '@leagueplatform/onboarding';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';

type FeatureHighlightsProps = {
  onOnboardingComplete: () => void;
};

export function FeatureHighlights({
  onOnboardingComplete,
}: FeatureHighlightsProps) {
  return (
    <FullScreenContainer>
      <OnboardingRoutesWithProvider
        contentfulId={import.meta.env.VITE_FEATURE_HIGHLIGHTS_ID}
        tenantId={import.meta.env.VITE_TENANT_ID}
        onOnboardingComplete={onOnboardingComplete}
      />
    </FullScreenContainer>
  );
}
