import { getCurrentLocale } from '@leagueplatform/locales';

export const getLocaleConstant = (localeOptions: {
  en: string;
  es: string;
}) => {
  const currentLocale = getCurrentLocale();
  if (currentLocale === 'en' || currentLocale === 'es') {
    return localeOptions[currentLocale];
  }
  return localeOptions.en;
};
