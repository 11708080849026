import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { Box } from '@leagueplatform/genesis-core';
import { AppNavBarComponent } from 'components/header-nav/navbar/nav.component';
import { MAX_CONTENT_WIDTH } from 'common/ui';
import { PropsWithChildren } from 'react';

type BasePageProps = {
  isPrivatePage?: boolean;
};

export const BasePage = ({
  children,
  isPrivatePage = true,
}: PropsWithChildren<BasePageProps>) => {
  useScrollTopOnLocationChange();

  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          position: 'relative',
          width: '100%',
          maxWidth: `calc(${MAX_CONTENT_WIDTH}px + $oneAndHalf * 2)`,
          paddingInline: '$oneAndHalf',
          marginInline: 'auto',
        }}
      >
        {children}
      </Box>
    </>
  );
};
