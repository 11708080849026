import React from 'react';
import {
  HeadingText,
  HeadingBar,
  Modal,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { ModalActionButton } from 'components/modals/common/modal-action-button.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';

interface Props {
  children: React.ReactNode;
  title?: string;
  maxWidth?: number;
}

export const BaseModalContainer = ({
  title,
  maxWidth = 583,
  children,
}: Props) => {
  const isBelowTablet = useIsBelowTablet();
  return (
    <Modal.Content
      showCloseButton={false}
      padding="$none"
      layout={isBelowTablet ? 'fullscreen' : 'standard'}
      css={{
        '.GDS-modal-content': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
        '.GDS-modal': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: isBelowTablet ? '100%' : maxWidth,
          left: isBelowTablet ? '0' : undefined,
          right: isBelowTablet ? '0' : undefined,
        },
      }}
    >
      <HeadingBar
        rightAction={
          <ModalActionButton
            actionType="close"
            labelId="STR_CLOSE"
            cssStyle={{ border: 'none' }}
          />
        }
        title={
          title ? (
            <HeadingText level="display" size="sm">
              {title}
            </HeadingText>
          ) : null
        }
      />
      <StackLayout
        css={{
          paddingInline: '$oneAndHalf',
          paddingBlockEnd: '$oneAndHalf',
        }}
        spacing="$two"
        verticalAlignment="spaceBetween"
      >
        {children}
      </StackLayout>
    </Modal.Content>
  );
};
