import { useCallback, useEffect, useState } from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { ErrorState } from '@leagueplatform/web-common-components';
import { useLocalStorage } from 'hooks/use-local-storage';
import { FeatureHighlights } from 'components/onboarding/feature-highlights.component';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { UserSetupFormComponent } from '../terms-and-conditions/user-setup-form.component';

export const OnboardingWrapper = ({ children }: { children: JSX.Element }) => {
  const [featureHighlightCompleted, setFeatureHighlightCompleted] =
    useLocalStorage('featureHighlightCompleted', false);
  const [showFeatureHighlights, setShowFeatureHighlights] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFeatureHighlights(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleFeatureHighlightCompleted = useCallback(() => {
    setFeatureHighlightCompleted(true);
  }, [setFeatureHighlightCompleted]);

  const {
    data: userData,
    isLoading: isLoadingUserProfile,
    isError: isErrorUserProfile,
  } = useGetUserProfile();

  const date = userData?.userProfile?.memberTermsAndConditions?.dateAccepted;

  if (
    (!featureHighlightCompleted && !showFeatureHighlights) ||
    isLoadingUserProfile
  ) {
    return <FullPageLoadingSpinner />;
  }

  if (isErrorUserProfile) return <ErrorState />;

  if (!date) {
    return <UserSetupFormComponent />;
  }

  if (!featureHighlightCompleted && showFeatureHighlights) {
    return (
      <FeatureHighlights
        onOnboardingComplete={handleFeatureHighlightCompleted}
      />
    );
  }

  return children;
};
