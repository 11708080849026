import {
  HeadingText,
  ParagraphText,
  ImageMessage,
  Modal,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import errorIcon from 'assets/error-icon.svg';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import { IMAGE_MESSAGE_ICON_WIDTH } from 'common/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';
import { ModalActionButton } from './common/modal-action-button.component';

export const AccountDeletedModal = () => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();

  return (
    <BaseModalContainer>
      <ImageMessage
        image={errorIcon}
        imageWidth={IMAGE_MESSAGE_ICON_WIDTH}
        css={{
          paddingBlockStart: '$one',
          paddingBlockEnd: '$two',
          textAlign: isBelowTablet ? 'center' : 'left',
        }}
      >
        <Modal.Title>
          <HeadingText level="2" size="lg" css={{ marginBlockEnd: '$one' }}>
            {formatMessage({ id: 'STR_ACCOUNT_DELETED_TITLE' })}
          </HeadingText>
        </Modal.Title>
        <ParagraphText>
          {formatMessage({ id: 'STR_ACCOUNT_DELETED_BODY_1' })}
        </ParagraphText>
        <ParagraphText>
          {formatMessage({ id: 'STR_ACCOUNT_DELETED_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
      <ModalActionButton
        actionType="submit"
        labelId="STR_I_UNDERSTAND"
        cssStyle={{
          background: '$interactiveActionPrimary',
          alignSelf: 'flex-end',
        }}
      />
    </BaseModalContainer>
  );
};
